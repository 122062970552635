{
  "name": "@awo-care/source",
  "versionCustomer": "0.0.124",
  "buildDateCustomer": "2025-02-09 21:27:54",
  "versionEmployee": "0.0.37",
  "buildDateEmployee": "2025-02-05 10:25:23",
  "license": "MIT",
  "scripts": {
    "update-version": "node update-version.js",
    "update-version:customer": "node update-version.js customer",
    "update-version:employee": "node update-version.js employee",
    "customer-ui": "nx serve customer-ui --port=4200",
    "employee-ui": "nx serve employee-ui --port=4201",
    "start": "nx run-many --target=serve --all --maxParallel=100 ",
    "deploy": "npm run update-version && npm run build && firebase deploy --only hosting",
    "deploy:customer": "npm run update-version:customer && nx run-many -t build --projects customer-ui --parallel --configuration=development && firebase use dev && firebase deploy --only hosting:customer-ui",
    "deploy:employee": "npm run update-version:employee && nx run-many -t build --projects employee-ui --parallel --configuration=development && firebase use dev && firebase deploy --only hosting:employee-ui",
    "deploy-prod": "npm run update-version && npm run build && firebase deploy --only hosting",
    "deploy-prod:customer": "npm run update-version:customer && nx run-many -t build --projects customer-ui --parallel --configuration=production && firebase use prod && firebase deploy --only hosting:customer-ui",
    "deploy-prod:employee": "npm run update-version:employee && nx run-many -t build --projects employee-ui --parallel --configuration=production && firebase use prod && firebase deploy --only hosting:employee-ui",
    "pack": "repopack",
    "pack:customer": "repomix -c repomix-customer.config.json",
    "pack:employee": "repomix -c repomix-employee.config.json",
    "extract:customer": "nx extract-i18n customer-ui",
    "extract:employee": "nx extract-i18n employee-ui",
    "emulator": "firebase emulators:start --import=./firebase-emulator-data --export-on-exit=./firebase-emulator-data",
    "emulator:functions": "firebase emulators:start --only functions"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.1.0",
    "@angular/cdk": "19.1.0",
    "@angular/common": "19.1.0",
    "@angular/compiler": "19.1.0",
    "@angular/core": "19.1.0",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "19.1.0",
    "@angular/material": "19.1.0",
    "@angular/platform-browser": "19.1.0",
    "@angular/platform-browser-dynamic": "19.1.0",
    "@angular/router": "19.1.0",
    "anguland": "^0.0.16",
    "firebase-functions": "^6.0.1",
    "html-to-text": "^9.0.5",
    "i18n-iso-countries": "^7.13.0",
    "mammoth": "^1.8.0",
    "ng-recaptcha-2": "^15.0.1",
    "ngx-material-timepicker": "^13.1.1",
    "nodemailer": "^6.9.16",
    "qrcode": "^1.5.3",
    "repomix": "^0.2.6",
    "rxjs": "~7.8.0",
    "sanitize-html": "^2.13.1",
    "xlsx": "^0.18.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.3",
    "@angular-devkit/core": "19.0.3",
    "@angular-devkit/schematics": "19.0.4",
    "@angular/cli": "~19.0.0",
    "@angular/compiler-cli": "19.1.0",
    "@angular/google-maps": "^18.2.10",
    "@angular/language-service": "19.1.0",
    "@angular/localize": "19.1.0",
    "@angular/service-worker": "19.1.0",
    "@eslint/js": "^9.8.0",
    "@nx/angular": "20.3.2",
    "@nx/devkit": "20.3.2",
    "@nx/esbuild": "20.3.2",
    "@nx/eslint": "20.3.2",
    "@nx/eslint-plugin": "20.3.2",
    "@nx/jest": "20.3.2",
    "@nx/js": "20.3.2",
    "@nx/playwright": "20.3.2",
    "@nx/web": "20.3.2",
    "@nx/workspace": "20.3.2",
    "@playwright/test": "^1.36.0",
    "@schematics/angular": "19.0.4",
    "@swc-node/register": "~1.9.1",
    "@swc/core": "~1.5.7",
    "@swc/helpers": "~0.5.11",
    "@types/google.maps": "^3.58.1",
    "@types/html-to-text": "^9.0.4",
    "@types/jest": "^29.5.12",
    "@types/node": "18.16.9",
    "@types/nodemailer": "^6.4.17",
    "@types/qrcode": "^1.5.5",
    "@types/sanitize-html": "^2.13.0",
    "@typescript-eslint/eslint-plugin": "^8.13.0",
    "@typescript-eslint/parser": "^8.13.0",
    "@typescript-eslint/utils": "^8.13.0",
    "angular-eslint": "19.0.2",
    "esbuild": "^0.19.2",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-playwright": "^1.6.2",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-preset-angular": "14.4.2",
    "ng-extract-i18n-merge": "^2.14.0",
    "nx": "20.3.2",
    "prettier": "^2.6.2",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "tslib": "^2.3.0",
    "typescript": "5.6.2",
    "typescript-eslint": "^8.13.0"
  }
}